import { mapActions, mapGetters, mapMutations } from 'vuex'
import validationMixin from '@/mixins/validation'
import { debounce } from 'lodash'
import moment from 'moment'
import { tariffsListTableHead } from '../../helpers'

export default {
  name: 'user-admins-tariffs',
  mixins: [validationMixin],
  data () {
    return {
      valid: false,
      page: 1,
      showDialogTariff: false,
      showRemoveDialog: false,
      deletedItem: null,
      editedItem: null,

      keywords: '',
      payload: {
        tariffId: '',
        activeTo: ''
      },

      search: null,
      searchInput: null
    }
  },
  computed: {
    ...mapGetters({
      userTariffs: 'userTariffs/userTariffs',
      userTariffsLength: 'userTariffs/userTariffsLength',
      isLoadingUserTariffs: 'userTariffs/isLoadingUserTariffs',
      limit: 'userTariffs/limit',
      skip: 'userTariffs/skip',
      isLoading: 'userTariffs/isLoading',

      tariffs: 'userTariffs/tariffs',
      isTariffsLoading: 'userTariffs/isTariffsLoading'
    }),
    tariffsListTableHead
  },
  created () {
    if (!this.userTariffs.length) {
      this.fetchUserTariffs({
        userId: this.$route.params.id
      })
    }
    if (this.userTariffs) {
      if (this.skip !== 0) {
        this.page = Math.round(this.skip / this.limit) + 1
      }
    }
    if (!this.tariffs.length) this.fetchTariffs({ keywords: '' })
  },
  methods: {
    ...mapActions({
      fetchTariffs: 'userTariffs/GET_TARIFFS',
      fetchUserTariffs: 'userTariffs/GET_USER_TARIFFS',
      remove: 'userTariffs/DELETE',
      update: 'userTariffs/UPDATE',
      create: 'userTariffs/CREATE'
    }),
    ...mapMutations({
      changeSkip: 'userTariffs/CHANGE_SKIP',
      setUserTariffs: 'userTariffs/SET_USER_TARIFFS',
      setTariffs: 'userTariffs/SET_TARIFFS'
    }),
    changeInputTariff: debounce(function (val) {
      if (!val) return
      if (this.keywords !== val) {
        this.keywords = val
        this.fetchTariffs({
          keywords: val,
          id: this.payload.tariffId
        })
      }
    }, 500),
    autoCompleteChanged (e) {
      this.payload.tariffId = e.id
      this.search = e
    },
    // end //
    changeDialogAction (item = null, status = false) {
      this.showDialogTariff = status
      this.editedItem = item
      if (!status) {
        this.payload.tariffId = ''
        this.payload.activeTo = ''
        this.search = null
        this.searchInput = null
      }
    },

    removeItem () {
      this.remove({
        userId: this.$route.params.id,
        id: this.deletedItem.tariff_id
      }).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    changeDialogDelete (item = null) {
      this.showRemoveDialog = !!item
      this.deletedItem = item
    },

    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchUserTariffs({ userId: this.$route.params.id })
    },
    formattedDate (date) {
      if (!date) return ''
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    createRequestPayload () {
      const formData = new FormData()
      formData.append('tariff_id', this.payload.tariffId)
      formData.append('userId', this.$route.params.id)
      formData.append('active_to', this.formattedDate(this.payload.activeTo))
      if (this.editedItem) {
        formData.append('_method', 'patch')
        formData.append('id', this.editedItem.id)
      }
      return formData
    },
    submit () {
      this.$refs.form.validate()
      this.$refs.observer.validate()
      if (this.valid) {
        if (this.editedItem) {
          this.update(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('userSuccessUpdate'))
            this.changeDialogAction()
          })
        } else {
          this.create(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('userSuccessUpdate'))
            this.changeDialogAction()
          })
        }
      }
    }
  },
  destroyed () {
    this.setUserTariffs([])
    this.setTariffs([])
  }
}
